<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Membership Number"
            label-for="helperInput"
            description="Enter the membership number"
          >
            <b-form-input
              id="MembershipNumber"
              v-model="memberId"
              placeholder="Membership Number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Mobile Number"
            label-for="helperInput"
            description="You can inclued 852 / 853 (e.g. 85223456789)"
          >
            <b-form-input
              id="MobileNumber"
              v-model="mobile"
              placeholder="Mobile Number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <!-- <label>Email Address</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="emailFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:emailFilter', val)"
          /> -->
          <b-form-group
            label="Email Address"
            label-for="helperInput"
          >
            <b-form-input
              id="EmailAddress"
              v-model="email"
              placeholder="Email Address"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            @click="SearchData()"
          >
            <span class="text-nowrap">Search Customer</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
  BFormGroup, BFormInput,
  BButton,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BButton,
    // vSelect,
  },
  props: {
    memberIdFilter: {
      type: [String, null],
      default: null,
    },
    mobileFilter: {
      type: [String, null],
      default: null,
    },
    emailFilter: {
      type: [String, null],
      default: null,
    },
    // roleOptions: {
    //   type: Array,
    //   required: true,
    // },
    // planOptions: {
    //   type: Array,
    //   required: true,
    // },
    // statusOptions: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    return {
      memberId: '',
      mobile: '',
      email: '',
    }
  },
  methods: {
    SearchData() {
      this.$emit('update:memberIdFilter', this.memberId)
      this.$emit('update:mobileFilter', this.mobile)
      this.$emit('update:emailFilter', this.email)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
