<template>
  <div>
    <customer-list-add-new
      :is-add-new-customer-sidebar-active.sync="isAddNewCustomerSidebarActive"
      :area-code-options="areaCodeOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <customers-list-filters
      :member-id-filter.sync="memberIdFilter"
      :mobile-filter.sync="mobileFilter"
      :email-filter.sync="emailFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">

              <b-button
                v-if="$can('edit', 'Customer')"
                variant="primary"
                @click="isAddNewCustomerSidebarActive = true"
              >
                <span class="text-nowrap">Add Customer</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCustomerListTable"
        class="position-relative"
        :items="fetchCustomers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(PurchasedSpecialOrigin)="data">
          <span>
            {{ data.item.PurchasedSpecialOrigin ? 'Y' : 'N' }}
          </span>
        </template>
        <!-- Column: Actions -->
        <template
          v-if="$can('edit', 'Customer')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'customer-edit', params: { id: data.item.ID } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCustomers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  // BFormInput,
  BButton,
  BTable,
  // BMedia,
  // BAvatar,
  // BLink,
  // BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
// import { avatarText } from '@core/utils/filter'
import CustomersListFilters from './CustomersListFilters.vue'
import useCustomersList from './useCustomersList'
import CustomerStoreModule from '../customerStoreModule'
import CustomerListAddNew from './CustomerListAddNew.vue'

export default {
  components: {
    CustomersListFilters,
    CustomerListAddNew,

    BCard,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    // BMedia,
    // BAvatar,
    // BLink,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-customer'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) { store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, CustomerStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) { store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME) }
    })

    const isAddNewCustomerSidebarActive = ref(false)

    const areaCodeOptions = [
      { label: '+852', value: '852' },
      { label: '+853', value: '853' },
    ]

    const {
      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,

      // UI
      resolveCustomerRoleVariant,
      resolveCustomerRoleIcon,
      resolveCustomerStatusVariant,

      // Extra Filters
      memberIdFilter,
      mobileFilter,
      emailFilter,
    } = useCustomersList()

    return {
      // Sidebar
      isAddNewCustomerSidebarActive,

      fetchCustomers,
      tableColumns,
      perPage,
      currentPage,
      totalCustomers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerListTable,
      refetchData,

      // Filter
      // avatarText,

      // UI
      resolveCustomerRoleVariant,
      resolveCustomerRoleIcon,
      resolveCustomerStatusVariant,

      // roleOptions,
      // planOptions,
      // statusOptions,
      areaCodeOptions,

      // Extra Filters
      memberIdFilter,
      mobileFilter,
      emailFilter,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
