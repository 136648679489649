import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Auth/Customer/List', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Auth/Customer/Details/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomer(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Auth/Customer/Add', userData)
          .then(response => resolve(response))
          .catch(error => {
            reject(error.response)
          })
      })
    },
    updateCustomer(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('/Auth/Customer/Update', userData)
          .then(response => resolve(response))
          .catch(error => {
            reject(error.response)
          })
      })
    },
  },
}
